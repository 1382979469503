import {FeatureFlag} from "@servicesApp/feature-flags";
import {isBrowser} from "./isBrowser";
import {ValueOf} from "src/types";

import monolith from "./fetch/fetches";
import {IncomingMessage} from "http";

const label = {
  SELECT_CLINIC: "SelectClinic",
  CLINIC_MAP: "ClinicMap",
  CLINIC_OPTIONS: "ClinicOptions",
  CLINIC_PHONE_NUMBER: "ClinicPhoneNumber",
  CLINIC_PHOTO: "ClinicPhoto",
  CONTACT_SUPPORT: "ContactSupport",
  SCHEDULING_WIDGET_FIRST_LOAD_TIME: "SchedulingWidgetFirstLoadTime",
  SCHEDULING_WIDGET_SLOT_LOAD_TIME: "SchedulingWidgetSlotLoadTime",
  APPOINTMENT_BOOKING_WEBSITE_WIDGET: "AppointmentBooking - WebsiteWidget",
  APPOINTMENT_BOOKING_REASON_WIDGET: "AppointmentBooking - ReasonWidget",
  APPOINTMENT_BOOKING_HOMEWIDGET: "AppointmentBooking - HomeWidget",
  APPOINTMENT_REASON: "AppointmentReason",
  LOCATION_INDEX_VIEW_SWITCH: "LocationIndexViewSwitch",
  COVID: "Covid",
  SERVICES: "Services",
  LOCATIONS: "Locations",
  COMPANY: "Company",
  MENU: "Menu",
  ACCOUNT: "Account",
  INSURANCES: "Insurances",
  LANGUAGES: "Languages",
  BOOKING: "Booking",
  APPOINTMENT_BOOKING_LOCATION_EXPLORER: "AppointmentBooking - LocationFooterWidget",
  AD_UNIT: "AdUnit",
  ADJUST_LOCATION: "AdjustLocation",
  REGION: "Region",
  REGION_DROP_DOWN: "RegionDropDown",
  APPT_REASON_OPTIONS: "ApptReasonOptions",
} as const;

const category = {
  EXPERIMENT_ASSIGNMENT: "ExperimentAssignment",
  LOCATION_DISCOVERY: "LocationDiscovery",
  WEBSITE_PERFORMANCE: "WebsitePerformance",
  LOCATION_PAGE_INFO: "LocationPageInfo",
  APPOINTMENT_SCHEDULING: "AppointmentScheduling",
  WEBSITE_PAGE_CHANGED: "WebsitePageChanged",
  NAVIGATION_DISCOVERY: "NavigationDiscovery",
  SUPPORT: "Support",
  COVID_TESTING_MAP: "CovidTestingMap",
  REASON_DISCOVERY: "ReasonDiscovery",
} as const;

const action = {
  CLICKED: "Clicked",
  VIEWED: "Viewed",
  LOADED: "Loaded",
  SCROLLED: "Scrolled",
  SEARCHED: "Searched",
} as const;

export type AnalyticsEvent = {
  category: ValueOf<typeof category>;
  action: ValueOf<typeof action>;
  value?: unknown;
  label?: ValueOf<typeof label> | FeatureFlag;
  extraData?: Record<string, unknown>;
};

type PostAnalyticsEventParams = AnalyticsEvent & {
  req?: IncomingMessage;
  headers?: Record<string, unknown>;
};

const post = ({extraData = {}, req, ...data}: PostAnalyticsEventParams): Promise<Response> => {
  const domain = isBrowser() ? window.location.href : req?.headers.location;

  return monolith()("/hib/event-tracking", {
    method: "POST",
    body: JSON.stringify({
      ...data,
      extraData: JSON.stringify({
        domain,
        platform: "web",
        ...extraData,
      }),
    }),
  });
};

export const analytics = {
  category,
  action,
  label,
  post,
};
