import Image, {ImageProps} from "next/legacy/image";
import React, {memo} from "react";
import nextImageSource, {ImageExtension} from "src/utils/nextImageSource";

export type NextImageProps = ImageProps & {extension?: ImageExtension};

/**
 @deprecated direct import from `next/image` instead
 */
const NextImage: React.FC<NextImageProps> = memo(({src, extension = "jpg", className, ...rest}) => {
  const layoutClass = "layout" in rest && rest.layout === "fill" ? "h100p obfc" : "";

  const classes = `pos-a top0 right0 bottom0 left0 minw100p minh100p ${layoutClass} ${
    className || ""
  }`;

  const srcStr = src as string;

  // isFullPath = true : if an external
  const isFullPath = srcStr.startsWith("http");

  const source = isFullPath ? src : nextImageSource(srcStr, extension);

  if (extension === "svg") {
    return (
      <img
        alt={rest.alt || (source as string)}
        className={className}
        src={source as string}
        {...rest}
      />
    );
  }
  return (
    <Image
      {...rest}
      priority={rest.priority} // always show images in CI
      className={classes}
      src={source}
    />
  );
});

NextImage.displayName = "NextImage";

export default NextImage;
