import states from "us-state-codes";

import {RegionStateMap} from "../constants/regionStateMap";

export const getStateCodeByName = (name = ""): string =>
  states.getStateCodeByStateName(name.replace(/-/g, " ")) || "";

/**
 *
 * @param cityAndState i.e. "San Francisco, CA" or "California"
 * @returns [city, state]
 */
export const getCityAndState = (
  cityAndState: string,
): [city: string | null, state: string | null] => {
  if (!cityAndState) {
    return [null, null];
  }
  const keyVals = cityAndState.split(",");
  const hasCity = keyVals.length > 1;
  const state = hasCity ? states.getStateNameByStateCode(keyVals[1]) : keyVals[0];
  const city = hasCity ? keyVals[0] : null;
  // @ts-expect-error TS2322: Type 'string | null | undefined' is not assignable to type 'string | null'.
  return [city, state];
};

export const getStateCodeFromRegionSlug = (string: string | unknown): string | null =>
  // @ts-expect-error TS2532: Object is possibly 'undefined'.
  typeof string === "string" && string in RegionStateMap ? RegionStateMap[string].code : null;
